.movies-list {
  // display: flex;
  // justify-content: center;
  overflow: hidden;
  position: relative;
  height: 720px;
}
// .slick-slider {
//   .slick-slide {
//     // width: 250px !important;
//     // margin: 0 30px;
//     display: flex !important;
//     justify-content: center !important;
//   }
// }
