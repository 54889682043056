@import "../../../Assets/sass//main-variables.scss";
.register-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-top: 100px;
  gap: 1rem;
  & button {
    margin-top: 2rem;
  }
  // .form__fields {
  //   width: 100%;
  //   max-width: 550px;
  //   display: flex;
  //   flex-direction: column;
  //   gap: 1rem;
  //   align-items: center;
  // }
}

@media (max-width: 1440px) {
  .register-form {
    padding: 0 80px;
  }
}
@media (max-width: 1024px) {
  .register-form {
    padding: 0 50px;
    .form__fields {
      gap: 0;
    }
  }
}

@media (max-width: 768px) {
  .register-form {
    margin-top: 0px;
    padding: 0;
    gap: 0.5rem;
  }
}
