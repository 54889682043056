@import "../../../Assets/sass/main-variables.scss";

.auth-text-field-container {
  padding: 0 0 1rem 0;
  position: relative;
  width: 100%;
  max-width: 550px;
  .auth-text-field {
    background-color: #ceaff542;
    border-radius: 12px;
    padding: 0 2rem;
    height: 80px;
    width: 100%;
    max-width: 550px;
    font-size: 1.5rem;
    font-family: "Cairo", sans-serif;
    color: $main-color;

    &:focus {
      outline: none;
      border: 1px solid $main-color;
      -webkit-box-shadow: 0 0 10px #75579870;
      box-shadow: 0 0 10px #75579870;
    }
    &:-webkit-autofill {
      outline: none;
      border: 1px solid $main-color;
      -webkit-box-shadow: 0 0 10px #75579870;
      box-shadow: 0 0 10px #75579870;
      -webkit-text-fill-color: $main-color;
      &:hover {
        color: $main-color;
      }
      // -webkit-text-fill-color: #371263 !important;
    }
    &::placeholder {
      color: #7557987c;
    }
  }
  .error {
    color: red;
    font-size: 1.2rem;
    position: absolute;
    bottom: -1rem;
    left: 0;
    font-weight: 400;
    &.rtl {
      left: unset;
      right: 0;
    }
  }
}

@media (max-width: 1440px) {
  .auth-text-field-container {
    .auth-text-field {
      height: 70px;
      font-size: 1.25rem;
    }
  }
}

@media (max-width: 768px) {
  .auth-text-field-container {
    .auth-text-field {
      height: 60px;
      font-size: 1.5rem;
      border-radius: 6px;
      background-color: rgb(129 206 252 / 30%);
      color: #FFF;
      &:-webkit-autofill {
        // -webkit-background-color: rgba(129, 207, 252, 0.30) !important;
        -webkit-text-fill-color: #062132;
        -webkit-box-shadow: 0 0 0 30px #81CFFC4C inset !important;
        // -webkit-box-shadow: 0 0 0 30px #e7d2ff47 inset !important;
        color: #062132 !important;
      }

      &::placeholder {
        color: #e7d2ff47;
      }
    }
  }
}
