@import "./Assets/sass/main-variables.scss";

// @font-face {
//   font-family: "din";
//   src: local("din"),
//     url("./Assets/Fonts/din-next-lt-w23-light.ttf") format("truetype");
//   font-weight: 300;
// }
// @font-face {
//   font-family: "din";
//   src: local("din"),
//     url("./Assets/Fonts/din-next-lt-w23-regular.ttf") format("truetype");
//   font-weight: 400;
// }
// @font-face {
//   font-family: "din";
//   src: local("din"),
//     url("./Assets/Fonts/din-next-lt-w23-medium.ttf") format("truetype");
//   font-weight: 700;
// }
// @font-face {
//   font-family: "din";
//   src: local("din"),
//     url("./Assets/Fonts/din-next-lt-w23-bold.ttf") format("truetype");
//   font-weight: 800;
// }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 0;
  padding: 0;
  margin: 0;
}
body {
  font-size: 16px;
  margin: 0;
  font-family: "Cairo", sans-serif;

  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden ;
  */
  background-image: url(./Assets/Images/body-bg1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
  // width: 100vw;
  overflow-x: hidden;
  // height: 5000000px;
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #3db9ec;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #bb84ff;
  }
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: unset;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding-bottom: 6px !important;
  text-align: unset;
}
