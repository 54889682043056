.slider-item {
  position: relative;
  width: 100%;
  height: 110%;
  display: block !important;
  direction: rtl;

  .slider-item-single {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .container {
      height: 100%;
      position: relative;
      .flex {
        display: flex;
        justify-content: flex-start;
        [dir="ltr"] & {
          justify-content: flex-end;
        }
        height: 85vh;
        position: relative;
        .slider-caption {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          // text-align: right;
          width: 75vw;
          z-index: 99999999999;
          max-width: 75vw;
          h4 {
            font-size: 70px;
            font-weight: 700;
            color: #81cefc;
            width: 100%;
          }
          p {
            font-size: 90px;
            font-weight: 700;
            color: #fff;
            width: 100%;
            text-align: unset;
            // padding-bottom: 50px !important;
          }
          body[dir="ltr"] & {
            align-items: flex-start;
            text-align: left;
            h4 {
              text-align: left;
            }
          }
        }
      }
    }
  }
  .header-overlay {
    background: linear-gradient(
      180deg,
      rgba(59, 33, 90, 0.5) 0%,
      rgba(50, 28, 74, 0.5) 50%,
      rgba(62, 35, 92, 1) 100%
    );
    position: absolute !important;
    height: 160vh !important;
    min-height: 1300px;
    max-height: 1700px;
    width: 100%;
    z-index: 1;
  }
}

.slider-slide {
  float: left !important;
}

@media (max-width: 1024px) {
  .slider-item {
    .slider-item-single {
      .container {
        .flex {
          .slider-caption {
            h4 {
              font-size: 40px;
            }
            p {
              font-size: 45px;
            }
          }
        }
      }
    }
  }
}
