.authLayout {
  min-height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .form {
    padding: 80px 0 200px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 175px;
  }
}

@media (max-width: 1440px) {
  .authLayout {
    .form {
      padding: 60px 0 180px;
      // gap: 100px;
    }
  }

  @media (max-width: 1024px) {
    .authLayout {
      .form {
        padding: 60px 0px 70px;
        // gap: 80px;
      }
    }
  }
}

@media (max-width: 768px) {
  .authLayout {
    grid-template-columns: 1fr;
    .form {
      padding: 40px 0 40px;
      display: none;
      // gap: 80px;
    }
  }
}
