@import "../../../../Assets/sass/main-variables.scss";
.footer-info {
  padding: 50px 0;
  .footer-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    .footer-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      img {
        // object-fit: fill;
        @media (min-width: 1200px) {
          width: 250px;
          // width: 180px;
          // height: 70px
        }

        // height: auto;
      }
    }
    .footer-nav {
      ul {
        // display: flex;
        justify-content: center;
        // align-items: center;
        gap: 2rem;
        flex-wrap: wrap;
        padding: 15px;
        width: 100%;
        color: white;
        li {
          a {
            padding: 10px;
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: all 0.5s ease-in-out;
            &:hover {
              color: #3ea9e9;
            }
          }
          &:last-child {
            border-left: none;
          }
        }
      }
    }
    .footer_sub__info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;

      .socials {
        ul {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          padding: 5px 10px;
          li {
            padding: 0 10px;
            font-size: 1.5rem;
            a {
              width: 100%;
              height: 100%;
              cursor: pointer;
              color: #3ea9e9;
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
      .copyrights {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: auto;
        flex-direction: column;
        p {
          font-size: 1.5rem;
          color: #fff;
          direction: ltr;
          span {
            color: #e8d6ff;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .footer-info {
    .footer-container {
      .footer-logo {
        img {
          // width: 120px;
          height: 50px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .footer-info {
    padding: 20px 0;
    .footer-container {
      gap: 1rem;
      .footer-logo {
        img {
          width: 120px;
          height: 48px;
        }
      }
      .footer-nav {
        ul {
          gap: 0.5rem;
        }
      }
      .footer_sub__info {
        .copyrights {
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
