.Toastify__toast {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 18px;
}

@media (max-width: 768px) {
  .Toastify__toast {
    width: 250px;
  }
  .Toastify__toast-container {
    display: flex;
    justify-content: center;
  }
  .Toastify__toast-body {
    font-size: 14px !important;
  }
}
