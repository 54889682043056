@import "../../../Assets/sass/main-variables.scss";
.main-card {
  position: relative;
  background-image: linear-gradient(180deg, #ffffff4f 0%, #22074375 100%);
  transition: all 0.3s ease-in-out;
  //   box-shadow: 0 5px 5px 3px #00000054;
  border-radius: 15px;
  box-shadow: 6px 0px 13px rgb(0 0 0 / 33%);
  .main-card-image {
    position: relative;
    height: 330px;
    border-radius: 15px;
    .offer-percent {
      position: absolute;
      top: 15px;
      left: 15px;
      background-color: rgb(255, 173, 10);
      border-radius: 8px;
      padding: 10px;
      min-width: 40px;
      color: #fff;
      font-weight: bold;
      z-index: 2;
    }
    img {
      width: 100%;
      border-radius: 15px;
      cursor: pointer;

      height: 100%;
      object-fit: cover;
    }
    // .card-overlay {
    //   background: linear-gradient(
    //     0deg,
    //     rgb(59 33 80 / 1%) 0%,
    //     rgb(50 28 64 / 10%) 50%,
    //     rgb(41 23 57 / 14%) 100%
    //   );
    //   position: absolute !important;
    //   height: 100% !important;
    //   width: 100%;
    //   z-index: 1;
    //   border-radius: 15px;
    // }
  }
  .main-card-footer {
    // background: rgba($main-color, .0.85);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: relative;
    // width: 100%;
    padding: 14px 10px 0 10px;
    height: 90px;
    transition: all 0.5s ease-in-out;
    h4 {
      font-weight: 400;
      text-align: center;
      padding: 5px;
      color: #fff;
      transition: all 0.5s ease-in-out;
      cursor: pointer;
      // white-space: nowrap;
      // overflow: hidden;
    }
    // button {
    //   background-color: #3db9ec;
    //   padding: 10px 50px;
    //   border-radius: 8px;
    //   color: #fff;
    //   font-size: 24px;
    //   font-weight: bold;
    //   position: absolute;
    //   top: -35px;
    //   left: calc(50%);
    //   transform: translateX(-50%);
    //   /* display: none; */
    //   opacity: 0;
    //   transition: all 0.5s ease-in-out;
    //   cursor: pointer;
    // }
    .rating {
      position: absolute;
      top: 65px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
    }
  }
  &:hover,
  &.loading {
    .main-card-footer {
      height: 140px;
      button {
        opacity: 1;
        top: 100px;
      }
      .rating {
        opacity: 1;
      }
      // h4 {
      //     padding-bottom: 30px;
      // }
    }
  }
}

@media (max-width: 1280px) {
  .main-card {
    &:hover {
      .main-card-footer {
        height: 100px;
        padding: 8px 10px 0 10px;
        button {
          top: 85px;
        }
      }
    }
    .main-card-image {
      height: 280px;
    }
    .main-card-footer {
      height: 70px;

      .rating {
        position: absolute;
        top: 62px;
        left: 50%;
      }
    }
  }
}

@media (max-width: 768px) {
}
