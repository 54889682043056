.slider-nav {
  position: absolute;
  left: 0%;
  top: 20%;
  width: 200px;
  height: 250px;
  z-index: 99;
  transition: all 0.5s ease-in-out;
  [dir="ltr"] & {
    left: auto;
    right: 0% !important;
  }
  .nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    .btn {
      position: relative;
      width: 100%;
      transition: all 0.5s ease-in-out;
      button {
        margin-top: 10px;
        padding: 8px 15px;
        font-size: 22px;
        font-weight: bold;
        border-radius: 12px;
        color: #fff;
        align-self: center;
        transition: all 0.5s ease-in-out;
      }
      button.active {
        background-color: rgba(238, 238, 238, 0.3) !important;
        font-size: 28px;
        transition: height 0.5s ease-in-out;
        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 70px;
          height: 3px;
          background: rgba(238, 238, 238, 0.3);
          left: 0;
          top: 50%;
          transition: all 0.5s ease-in-out;
          body[dir="ltr"] & {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }
}
