@import "../../../Assets/sass/main-variables.scss";
.forget-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-top: 170px;
  .form__fields {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    h6 {
      width: 100%;
      display: flex;
      color: #707070;
      font-size: 1.5rem;
    }
    .whats-contact {
      a {
        display: flex;
        gap: 4px;
        cursor: pointer;
        color: $main-color;
        font-size: 1.5rem;
        font-weight: 500;
        text-decoration: underline;
        & svg {
          color: $main-color;
          font-size: 2rem;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .forget-form {
    padding: 0 80px;
    margin-top: 100px;
  }
}
@media (max-width: 1024px) {
  .forget-form {
    padding: 0 50px;
    margin-top: 80px;
    .form__fields {
      gap: 0;
      .forget__password {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .forget-form {
    gap: 1rem;
    margin-top: 0px;
    .form__fields {
      gap: 1rem;
      .whats-contact {
        a {
          font-size: 1.25rem;
          color: #e7d2ff;
          svg {
            color: #e7d2ff;
          }
        }
      }
    }
  }
}
