.slick-slider {
  direction: ltr !important;
}
[dir="rtl"] .slick-slider {
  .slick-slide {
    float: left !important;
  }
}

.logo{
  object-fit: fill !important;
}