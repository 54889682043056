@import "../../../Assets/sass//main-variables.scss";
.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-top: 170px;
  .form__fields {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    .forget__password {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: -1rem;
      color: $main-color;
      font-size: 1.25rem;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}

@media (max-width: 1440px) {
  .login-form {
    padding: 0 80px;
    margin-top: 100px;
  }
}
@media (max-width: 1024px) {
  .login-form {
    padding: 0 50px;
    margin-top: 120px;
    .form__fields {
      gap: 0;
      .forget__password {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .login-form {
    margin-top: 0px;
  }
}
