.info {
  .small-form {
    display: none;
  }
  background-image: url("../../../Assets/Images/body-bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px 170px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;

  .info__logo {
    width: 200px;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .info__text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    .info__title {
      h1 {
        color: #ffad0a;
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
      }
    }
    .info__subtitle {
      height: 100%;
      padding-bottom: 5rem;
      justify-content: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #e8d6ff;
      text-align: right;
      gap: 1.5rem;
      &.ltr {
        text-align: left;
      }
      h2 {
        font-weight: 500;
        font-size: 2.25rem;
      }
      p {
        margin-right: 10px;
        font-size: 2rem;
        font-weight: 400;
      }
    }
  }

  .info__actions {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 1440px) {
  .info {
    padding: 50px 80px 180px;
    gap: 35px;
    .info__text {
      .info__title {
        h1 {
          font-size: 2rem;
        }
      }
      .info__subtitle {
        h2 {
          font-size: 1.75rem;
        }
        p {
          font-size: 1.75rem;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .info {
    padding: 60px 50px 70px;
    .info__logo {
      width: 170px;
      height: 60px;
    }
    .info__text {
      .info__title {
        h1 {
          font-size: 1.5rem;
        }
      }
      .info__subtitle {
        padding-bottom: 3rem;
        h2 {
          font-size: 1.5rem;
        }
        p {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .info {
    padding: 32px;
    justify-content: center;
    .info__logo{
      // height: 80px;
    }
    .small-form {
      display: flex;
      flex-direction: column;
      .login-form {
        gap: 3rem;
        padding: 0;
        .form__fields {
          .forget__password {
            color: #e7d2ff;
          }
        }
      }
    }
    .info__text {
      height: auto;
      .info__subtitle {
        display: none;
      }
    }
  }
}
