.offer {
  border-radius: 20px;
  margin-bottom: 30px;
  box-shadow: 0px -10px 20px #000000ad;
  overflow: hidden;
  *{
    text-align: justify;
  }
  .offer-head {
    font-size: 20px;
    position: relative;
    height: 340px;
    &::after {
      content: "''";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      background-image: linear-gradient(180deg, #ffffff00 0%, #42276129 100%);
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      @media(max-width: 570px){
        object-fit: cover;
      }
    }
    .offer-date {
      position: absolute;
      bottom: 15px;
      right: 35px;
      color: #ffad0a;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(40, 39, 37, 0.85);
      padding: 10px;
      border-radius: 15px;
      [dir="ltr"] & {
        right: auto;
        left: 35px;
      }
      .from,
      .to {
        padding: 5px;
      }
      @media(max-width: 570px){
        width: 95%;
        right: unset;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .offer-content {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 65px 35px;
    background-color: #ffffff0f;
    h4 {
      color: #ffad0a;
      font-weight: 400;
      font-size: 30px;
    }
    p {
      margin-top: 30px;
      font-weight: 400;
      font-size: 20px;
      color: #e2e2e2;
      letter-spacing: -0.8px;
    }
  }
}
