@import "../../../Assets/sass/main-variables.scss";
.auth__title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  h1 {
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    font-weight: 700;
    color: $main-color;
    z-index: 1;
    background-color: white;
    width: 70%;
    text-align: center;
    span {
      max-width: 240px;
      display: block;
    }
  }
  .line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 1px;
    background-color: $main-color;
    z-index: 0;
  }
}

@media (max-width: 1440px) {
  .auth__title {
    h1 {
      font-size: 2rem;
    }
  }
}
